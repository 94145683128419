var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-card', [_c('div', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search by Title/ Company/ Email/ Contact Person/ Mobile",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Select Next Contact Date",
      "config": {
        mode: 'range',
        defaultDate: [this.filterStartDate, this.filterEndDate]
      }
    },
    on: {
      "input": _vm.filterLeadsByDate
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)]), _c('b-card', [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterCountryIdOption,
      "reduce": function reduce(dept) {
        return dept.id;
      },
      "label": "name",
      "placeholder": "Select Country"
    },
    on: {
      "input": _vm.loadSelecterCountryLeads
    },
    model: {
      value: _vm.filterCountryId,
      callback: function callback($$v) {
        _vm.filterCountryId = $$v;
      },
      expression: "filterCountryId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterDepartmentIdOption,
      "reduce": function reduce(dept) {
        return dept.id;
      },
      "label": "name",
      "placeholder": "Select Department"
    },
    on: {
      "input": _vm.loadSelectedDepartmentLeads
    },
    model: {
      value: _vm.filterDepartmentId,
      callback: function callback($$v) {
        _vm.filterDepartmentId = $$v;
      },
      expression: "filterDepartmentId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterCategoryIdOption,
      "reduce": function reduce(category) {
        return category.id;
      },
      "label": "name",
      "placeholder": "Select Category"
    },
    on: {
      "input": _vm.loadSelectedCategoryLeads
    },
    model: {
      value: _vm.filterCategoryId,
      callback: function callback($$v) {
        _vm.filterCategoryId = $$v;
      },
      expression: "filterCategoryId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterStatusIdOption,
      "reduce": function reduce(status) {
        return status.id;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": _vm.loadSelectedStatusLeads
    },
    model: {
      value: _vm.filterStatusId,
      callback: function callback($$v) {
        _vm.filterStatusId = $$v;
      },
      expression: "filterStatusId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterSourceIdOption,
      "reduce": function reduce(source) {
        return source.id;
      },
      "label": "name",
      "placeholder": "Select Source"
    },
    on: {
      "input": _vm.loadSelectedSourceLeads
    },
    model: {
      value: _vm.filterSourceId,
      callback: function callback($$v) {
        _vm.filterSourceId = $$v;
      },
      expression: "filterSourceId"
    }
  })], 1)], 1)], 1)])], 1), _c('div', [_c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.MY_LEAD_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Add Lead ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "style-class": "vgt-table table-custom-style bordered condensed",
      "line-numbers": false,
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'last_contact_date',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "styleClass": "vgt-table striped table-custom-style"
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row2, _props$row3, _props$row4, _props$row4$departmen, _props$row4$departmen2, _props$row5, _props$row6, _props$row6$leadSourc, _props$row6$leadSourc2, _props$row7, _props$row8, _props$row8$leadCateg, _props$row8$leadCateg2, _props$column2, _props$row9, _props$row10, _props$row11, _props$row12, _props$row13, _props$row14, _props$row15, _props$row16, _props$row17, _props$row18, _props$row19, _props$row20, _props$row20$leadCoun, _props$row20$leadCoun2, _props$column3, _props$row21, _props$row22, _props$column4, _props$row24, _props$row24$user, _props$row24$user$dat, _props$row25, _props$row25$user, _props$row25$user$dat, _props$row26, _props$row26$user, _props$row26$user$dat, _props$row27, _props$row27$user, _props$row27$user$dat, _props$row28, _props$row28$user, _props$row28$user$dat, _props$row29, _props$row29$user, _props$row29$user$dat, _props$row30, _props$row30$user, _props$row30$user$dat, _props$row31, _props$row31$user, _props$row31$user$dat, _props$row32, _props$row32$user, _props$row32$user$dat, _props$column5, _props$row33, _props$row34, _props$row35, _props$column6, _props$row36, _props$row36$leadStat, _props$row36$leadStat2, _props$row37, _props$row37$leadStat, _props$row37$leadStat2, _props$row38, _props$row38$leadStat, _props$row38$leadStat2, _props$row39, _props$row40, _props$row40$leadStat, _props$row40$leadStat2, _props$row41, _props$row42, _props$row42$leadStat, _props$row42$leadStat2;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_title' ? [_c('span', [_c('b', [_vm._v("Title: ")]), _c('b-link', {
          attrs: {
            "href": _vm.getPageUrl(props.row.id)
          },
          on: {
            "click": function click($event) {
              var _props$row;
              return _vm.onShowPage(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.title) + " ")])], 1), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.department ? _c('div', [_c('span', [_c('b', [_vm._v("Dept: ")]), _vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : (_props$row4$departmen = _props$row4.department) === null || _props$row4$departmen === void 0 ? void 0 : (_props$row4$departmen2 = _props$row4$departmen.data) === null || _props$row4$departmen2 === void 0 ? void 0 : _props$row4$departmen2.name))])]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.leadSource ? _c('div', [_c('span', [_c('b', [_vm._v("Source: ")]), _vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$leadSourc = _props$row6.leadSource) === null || _props$row6$leadSourc === void 0 ? void 0 : (_props$row6$leadSourc2 = _props$row6$leadSourc.data) === null || _props$row6$leadSourc2 === void 0 ? void 0 : _props$row6$leadSourc2.name))])]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.leadCategory ? _c('div', [_c('span', [_c('b', [_vm._v("Category: ")]), _vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : (_props$row8$leadCateg = _props$row8.leadCategory) === null || _props$row8$leadCateg === void 0 ? void 0 : (_props$row8$leadCateg2 = _props$row8$leadCateg.data) === null || _props$row8$leadCateg2 === void 0 ? void 0 : _props$row8$leadCateg2.name))])]) : _vm._e()])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'client_info' ? [_c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.company_name ? _c('div', [_c('span', [_c('b', [_vm._v("Company Name: ")]), _vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.company_name))])]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row11 = props.row) !== null && _props$row11 !== void 0 && _props$row11.key_contact_person ? _c('div', [_c('span', [_c('b', [_vm._v("Contact Person: ")]), _vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.key_contact_person))])]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row13 = props.row) !== null && _props$row13 !== void 0 && _props$row13.email ? _c('div', [_c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_c('b', [_vm._v("Email: ")]), _c('b-link', {
          attrs: {
            "href": 'mailto:' + (props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.email)
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.email) + " ")])], 1)]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row16 = props.row) !== null && _props$row16 !== void 0 && _props$row16.mobile ? _c('div', [_c('b', [_vm._v("Mobile: ")]), _c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_c('b-link', {
          attrs: {
            "href": 'tel:' + (props === null || props === void 0 ? void 0 : (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : _props$row17.mobile)
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.mobile) + " ")])], 1)]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row19 = props.row) !== null && _props$row19 !== void 0 && _props$row19.leadCountry ? _c('div', [_c('b', [_vm._v("Country: ")]), _c('span', {
          attrs: {
            "className": "fw-bolder"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : (_props$row20$leadCoun = _props$row20.leadCountry) === null || _props$row20$leadCoun === void 0 ? void 0 : (_props$row20$leadCoun2 = _props$row20$leadCoun.data) === null || _props$row20$leadCoun2 === void 0 ? void 0 : _props$row20$leadCoun2.name) + " ")])]) : _vm._e()])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_member_info' ? [_vm._l(props.row.team.data, function (member, index) {
          return _c('span', {
            key: index
          }, [index <= 2 ? [_c('b-avatar', {
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
              "size": "26"
            }
          })] : _vm._e()], 2);
        }), (props === null || props === void 0 ? void 0 : (_props$row21 = props.row) === null || _props$row21 === void 0 ? void 0 : _props$row21.team_count) > 3 ? _c('span', [_vm._v(" + " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row22 = props.row) === null || _props$row22 === void 0 ? void 0 : _props$row22.team_count) - 3) + " more ")]) : _vm._e(), _c('b-button', {
          staticClass: "btn-icon rounded-circle",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row23, _props$row23$team;
              return _vm.showAllAddedMemberModal(props === null || props === void 0 ? void 0 : (_props$row23 = props.row) === null || _props$row23 === void 0 ? void 0 : (_props$row23$team = _props$row23.team) === null || _props$row23$team === void 0 ? void 0 : _props$row23$team.data);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UsersIcon"
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_user' ? [_c('div', {
          staticClass: "cell-with-description"
        }, [_c('span', [_c('b-avatar', {
          staticClass: "mx-1",
          attrs: {
            "src": (props === null || props === void 0 ? void 0 : (_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : (_props$row24$user = _props$row24.user) === null || _props$row24$user === void 0 ? void 0 : (_props$row24$user$dat = _props$row24$user.data) === null || _props$row24$user$dat === void 0 ? void 0 : _props$row24$user$dat.avatar) === '' ? '/avatar.svg' : props === null || props === void 0 ? void 0 : (_props$row25 = props.row) === null || _props$row25 === void 0 ? void 0 : (_props$row25$user = _props$row25.user) === null || _props$row25$user === void 0 ? void 0 : (_props$row25$user$dat = _props$row25$user.data) === null || _props$row25$user$dat === void 0 ? void 0 : _props$row25$user$dat.avatar
          }
        })], 1)]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row26 = props.row) !== null && _props$row26 !== void 0 && (_props$row26$user = _props$row26.user) !== null && _props$row26$user !== void 0 && (_props$row26$user$dat = _props$row26$user.data) !== null && _props$row26$user$dat !== void 0 && _props$row26$user$dat.name ? _c('div', [_c('span', {
          attrs: {
            "className": "fw-bolder"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row27 = props.row) === null || _props$row27 === void 0 ? void 0 : (_props$row27$user = _props$row27.user) === null || _props$row27$user === void 0 ? void 0 : (_props$row27$user$dat = _props$row27$user.data) === null || _props$row27$user$dat === void 0 ? void 0 : _props$row27$user$dat.name) + " ")])]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row28 = props.row) !== null && _props$row28 !== void 0 && (_props$row28$user = _props$row28.user) !== null && _props$row28$user !== void 0 && (_props$row28$user$dat = _props$row28$user.data) !== null && _props$row28$user$dat !== void 0 && _props$row28$user$dat.email ? _c('div', [_c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_c('b', [_vm._v("Email: ")]), _c('b-link', {
          attrs: {
            "href": 'mailto:' + (props === null || props === void 0 ? void 0 : (_props$row29 = props.row) === null || _props$row29 === void 0 ? void 0 : (_props$row29$user = _props$row29.user) === null || _props$row29$user === void 0 ? void 0 : (_props$row29$user$dat = _props$row29$user.data) === null || _props$row29$user$dat === void 0 ? void 0 : _props$row29$user$dat.email)
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row30 = props.row) === null || _props$row30 === void 0 ? void 0 : (_props$row30$user = _props$row30.user) === null || _props$row30$user === void 0 ? void 0 : (_props$row30$user$dat = _props$row30$user.data) === null || _props$row30$user$dat === void 0 ? void 0 : _props$row30$user$dat.email) + " ")])], 1)]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row31 = props.row) !== null && _props$row31 !== void 0 && (_props$row31$user = _props$row31.user) !== null && _props$row31$user !== void 0 && (_props$row31$user$dat = _props$row31$user.data) !== null && _props$row31$user$dat !== void 0 && _props$row31$user$dat.employee_number ? _c('div', [_c('span', [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row32 = props.row) === null || _props$row32 === void 0 ? void 0 : (_props$row32$user = _props$row32.user) === null || _props$row32$user === void 0 ? void 0 : (_props$row32$user$dat = _props$row32$user.data) === null || _props$row32$user$dat === void 0 ? void 0 : _props$row32$user$dat.employee_number) + " ")])]) : _vm._e()])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_contact_schedule' ? [_c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row33 = props.row) !== null && _props$row33 !== void 0 && _props$row33.last_contact_date ? _c('div', [_c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_c('b', [_vm._v(" Last Contacted : ")]), _c('b-badge', {
          attrs: {
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableLastContactDate(props === null || props === void 0 ? void 0 : (_props$row34 = props.row) === null || _props$row34 === void 0 ? void 0 : _props$row34.last_contact_date)) + " ")])], 1)]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [_c('div', [_c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_c('b', [_vm._v(" Next Contact: ")]), _c('b-badge', {
          attrs: {
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableLastContactDate(props === null || props === void 0 ? void 0 : (_props$row35 = props.row) === null || _props$row35 === void 0 ? void 0 : _props$row35.next_contact_date)) + " ")])], 1)])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field) === 'format_status' ? [_c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row36 = props.row) !== null && _props$row36 !== void 0 && (_props$row36$leadStat = _props$row36.leadStatus) !== null && _props$row36$leadStat !== void 0 && (_props$row36$leadStat2 = _props$row36$leadStat.data) !== null && _props$row36$leadStat2 !== void 0 && _props$row36$leadStat2.name ? _c('div', [_c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_c('b-badge', {
          staticClass: "badge-glow",
          style: {
            backgroundColor: props === null || props === void 0 ? void 0 : (_props$row37 = props.row) === null || _props$row37 === void 0 ? void 0 : (_props$row37$leadStat = _props$row37.leadStatus) === null || _props$row37$leadStat === void 0 ? void 0 : (_props$row37$leadStat2 = _props$row37$leadStat.data) === null || _props$row37$leadStat2 === void 0 ? void 0 : _props$row37$leadStat2.color_code
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row38 = props.row) === null || _props$row38 === void 0 ? void 0 : (_props$row38$leadStat = _props$row38.leadStatus) === null || _props$row38$leadStat === void 0 ? void 0 : (_props$row38$leadStat2 = _props$row38$leadStat.data) === null || _props$row38$leadStat2 === void 0 ? void 0 : _props$row38$leadStat2.name) + " ")])], 1)]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [(props === null || props === void 0 ? void 0 : (_props$row39 = props.row) === null || _props$row39 === void 0 ? void 0 : _props$row39.estimation_sent) == true && (props === null || props === void 0 ? void 0 : (_props$row40 = props.row) === null || _props$row40 === void 0 ? void 0 : (_props$row40$leadStat = _props$row40.leadStatus) === null || _props$row40$leadStat === void 0 ? void 0 : (_props$row40$leadStat2 = _props$row40$leadStat.data) === null || _props$row40$leadStat2 === void 0 ? void 0 : _props$row40$leadStat2.type) == 2 ? [_c('b-badge', {
          staticClass: "badge-glow mt-1",
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v(" Estimation Sent ")])] : (props === null || props === void 0 ? void 0 : (_props$row41 = props.row) === null || _props$row41 === void 0 ? void 0 : _props$row41.estimation_sent) == false && (props === null || props === void 0 ? void 0 : (_props$row42 = props.row) === null || _props$row42 === void 0 ? void 0 : (_props$row42$leadStat = _props$row42.leadStatus) === null || _props$row42$leadStat === void 0 ? void 0 : (_props$row42$leadStat2 = _props$row42$leadStat.data) === null || _props$row42$leadStat2 === void 0 ? void 0 : _props$row42$leadStat2.type) == 2 ? [_c('b-badge', {
          staticClass: "badge-glow mt-1",
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" Estimation Not Sent ")]), _c('br'), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm mt-1 mb-1 custom-font",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.showEstimationModal(props === null || props === void 0 ? void 0 : props.row);
            }
          }
        }, [_vm._v(" Submit Estimation ")])] : _vm._e()], 2)] : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.MY_LEAD_EDIT, _vm.permissions) || _vm.$permissionAbility(_vm.MY_LEAD_DELETE, _vm.permissions) ? _c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.MY_LEAD_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Show")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click() {
              return _vm.onCopy(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CopyIcon"
          }
        }), _c('span', [_vm._v("Copy")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.MY_LEAD_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.MY_LEAD_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1) : _vm._e()]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-all-added-member-form",
      "centered": "",
      "title": "Assigned Members",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAllAddedMemberModal
    }
  }, [_c('ul', _vm._l(_vm.assingedMember, function (member, index) {
    return _c('li', {
      key: index
    }, [_c('b-avatar', {
      staticStyle: {
        "margin": "1px"
      },
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    }), _vm._v(" " + _vm._s(member.name) + " ")], 1);
  }), 0)]), _c('b-modal', {
    attrs: {
      "id": "modal-lead-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Lead  Details ' : _vm.modelType == 'copyModel' ? 'Copy Lead ' : 'Create Lead ',
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "leadValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Lead Title ",
      "label-for": "title"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Lead Title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-2 mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', [_vm._v("Client Information")])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Company Name ",
      "label-for": "company_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "company name",
      "vid": "company_name",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Company Name"
          },
          model: {
            value: _vm.company_name,
            callback: function callback($$v) {
              _vm.company_name = $$v;
            },
            expression: "company_name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email ",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "company email",
      "vid": "email",
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "email",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Contact Email"
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Country ",
      "label-for": "country_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "country name",
      "vid": "country_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Choose here",
            "options": _vm.countryIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.setDialCodeAsCountry
          },
          model: {
            value: _vm.country_id,
            callback: function callback($$v) {
              _vm.country_id = $$v;
            },
            expression: "country_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "7",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company Address",
      "label-for": "address"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "company address",
      "vid": "address",
      "rules": "max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "address",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Company Address"
          },
          model: {
            value: _vm.address,
            callback: function callback($$v) {
              _vm.address = $$v;
            },
            expression: "address"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "5",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company Website Link ",
      "label-for": "website_link"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "website link",
      "vid": "website_link"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "website_link",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Website Link"
          },
          model: {
            value: _vm.website_link,
            callback: function callback($$v) {
              _vm.website_link = $$v;
            },
            expression: "website_link"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "city"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "city",
      "vid": "city",
      "rules": "max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter City Name"
          },
          model: {
            value: _vm.city,
            callback: function callback($$v) {
              _vm.city = $$v;
            },
            expression: "city"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "State ",
      "label-for": "state"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "state",
      "vid": "state"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "state",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter State Name"
          },
          model: {
            value: _vm.state,
            callback: function callback($$v) {
              _vm.state = $$v;
            },
            expression: "state"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Street ",
      "label-for": "street"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "street",
      "vid": "street"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "street",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Street Name"
          },
          model: {
            value: _vm.street,
            callback: function callback($$v) {
              _vm.street = $$v;
            },
            expression: "street"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Zip Code ",
      "label-for": "zip_code"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "zip code",
      "vid": "zip_code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "zip_code",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Zip Code"
          },
          model: {
            value: _vm.zip_code,
            callback: function callback($$v) {
              _vm.zip_code = $$v;
            },
            expression: "zip_code"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-2 mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', [_vm._v("Key Contact Person Information")])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Person Name ",
      "label-for": "key_contact_person"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "person name",
      "vid": "key_contact_person",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "key_contact_person",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Person Name"
          },
          model: {
            value: _vm.keyContactPerson,
            callback: function callback($$v) {
              _vm.keyContactPerson = $$v;
            },
            expression: "keyContactPerson"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Dial Code ",
      "label-for": "dial_code_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "dial code",
      "vid": "dial_code_country_id",
      "rules": _vm.mobile ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Choose here",
            "options": _vm.dialCodeCountryIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "dial_code"
          },
          model: {
            value: _vm.dial_code_country_id,
            callback: function callback($$v) {
              _vm.dial_code_country_id = $$v;
            },
            expression: "dial_code_country_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mobile",
      "label-for": "mobile"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "mobile",
      "vid": "mobile"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Number"
          },
          model: {
            value: _vm.mobile,
            callback: function callback($$v) {
              _vm.mobile = $$v;
            },
            expression: "mobile"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tel ",
      "label-for": "phone"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tel",
      "vid": "phone",
      "rules": "numeric"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Number"
          },
          model: {
            value: _vm.phone,
            callback: function callback($$v) {
              _vm.phone = $$v;
            },
            expression: "phone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-2 mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', [_vm._v("Lead Assignee Information")])])], 1), _vm.modelType == 'editModel' ? [_c('b-row', [_c('b-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Assigned Employee ",
      "label-for": "assign_users"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigned employee",
      "vid": "assign_users"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user-id",
            "placeholder": "Assign Employee",
            "options": _vm.userIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name",
            "multiple": "",
            "disabled": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                staticClass: "mr-1",
                attrs: {
                  "src": (data === null || data === void 0 ? void 0 : data.avatar) === '' ? '/avatar.svg' : data === null || data === void 0 ? void 0 : data.avatar
                }
              }), _c('div', [_c('div', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.name) + " "), _c('b-badge', {
                attrs: {
                  "pill": "",
                  "variant": "warning"
                }
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.employee_number))])], 1), _c('div', {
                staticClass: "font-small-2"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.email) + " ")])])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.assign_users,
            callback: function callback($$v) {
              _vm.assign_users = $$v;
            },
            expression: "assign_users"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3533693869)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Lead Source ",
      "label-for": "lead_source_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "lead source",
      "vid": "lead_source_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('v-select', {
          attrs: {
            "id": "head-id",
            "placeholder": "Choose a Lead Source",
            "options": _vm.sourceIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.lead_source_id,
            callback: function callback($$v) {
              _vm.lead_source_id = $$v;
            },
            expression: "lead_source_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 486088875)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Lead Category",
      "label-for": "lead_category_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lead category",
      "vid": "lead_category_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('v-select', {
          attrs: {
            "id": "head-id",
            "placeholder": "Choose a Category",
            "options": _vm.categoryIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.lead_category_id,
            callback: function callback($$v) {
              _vm.lead_category_id = $$v;
            },
            expression: "lead_category_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4058978474)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Department ",
      "label-for": "department_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "department name",
      "vid": "department_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Choose a Department",
            "options": _vm.departmentIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.department_id,
            callback: function callback($$v) {
              _vm.department_id = $$v;
            },
            expression: "department_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1442445548)
  })], 1)], 1)], 1)] : [_c('b-row', [_c('b-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Assigned Employee ",
      "label-for": "assign_users"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigned employee",
      "vid": "assign_users",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user-id",
            "placeholder": "Assign Employee",
            "options": _vm.userIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name",
            "multiple": "",
            "disabled": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                staticClass: "mr-1",
                attrs: {
                  "src": (data === null || data === void 0 ? void 0 : data.avatar) === '' ? '/avatar.svg' : data === null || data === void 0 ? void 0 : data.avatar
                }
              }), _c('div', [_c('div', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.name) + " "), _c('b-badge', {
                attrs: {
                  "pill": "",
                  "variant": "warning"
                }
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.employee_number))])], 1), _c('div', {
                staticClass: "font-small-2"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.email) + " ")])])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.assign_users,
            callback: function callback($$v) {
              _vm.assign_users = $$v;
            },
            expression: "assign_users"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Lead Status ",
      "label-for": "lead_status_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "lead status",
      "vid": "lead_status_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Assign a Status",
            "options": _vm.statusIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.lead_status_id,
            callback: function callback($$v) {
              _vm.lead_status_id = $$v;
            },
            expression: "lead_status_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Lead Source ",
      "label-for": "lead_source_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "lead source",
      "vid": "lead_source_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref21) {
        var errors = _ref21.errors;
        return [_c('v-select', {
          attrs: {
            "id": "head-id",
            "placeholder": "Choose a Source",
            "options": _vm.sourceIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.lead_source_id,
            callback: function callback($$v) {
              _vm.lead_source_id = $$v;
            },
            expression: "lead_source_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Lead Category",
      "label-for": "lead_category_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lead category",
      "vid": "lead_category_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var errors = _ref22.errors;
        return [_c('v-select', {
          attrs: {
            "id": "head-id",
            "placeholder": "Choose a Category",
            "options": _vm.categoryIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.lead_category_id,
            callback: function callback($$v) {
              _vm.lead_category_id = $$v;
            },
            expression: "lead_category_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Department ",
      "label-for": "department_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "department name",
      "vid": "department_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref23) {
        var errors = _ref23.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Choose a Department",
            "options": _vm.departmentIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.department_id,
            callback: function callback($$v) {
              _vm.department_id = $$v;
            },
            expression: "department_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Note",
      "label-for": "note"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "note",
      "vid": "note",
      "rules": "max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref24) {
        var errors = _ref24.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "note",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Take A Quick Note"
          },
          model: {
            value: _vm.note,
            callback: function callback($$v) {
              _vm.note = $$v;
            },
            expression: "note"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.modelType === 'editModel' ? [_c('b-img', {
    staticClass: "small-image",
    attrs: {
      "src": _vm.business_card_front
    }
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.modelType === 'editModel' ? [_c('b-img', {
    staticClass: "small-image",
    attrs: {
      "src": _vm.business_card_back
    }
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Business Card (Front)",
      "label-for": "business_card_front"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Business Card (Front)",
      "rules": "size:2048|ext:jpeg,png,jpg",
      "vid": "business_card_front"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref25) {
        var errors = _ref25.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "business_card_front",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.business_card_front_input,
            callback: function callback($$v) {
              _vm.business_card_front_input = $$v;
            },
            expression: "business_card_front_input"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Business Card (Back)",
      "label-for": "business_card_back"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Business Card (Back)",
      "rules": "size:2048|ext:jpeg,png,jpg",
      "vid": "business_card_back"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref26) {
        var errors = _ref26.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "business_card_back",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.business_card_back_input,
            callback: function callback($$v) {
              _vm.business_card_back_input = $$v;
            },
            expression: "business_card_back_input"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _vm.isLeadsFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('FinancialEstimationForm', {
    attrs: {
      "selectedLeadInfo": _vm.selectedLeadInfo,
      "leadTitle": _vm.leadTitle
    },
    on: {
      "loadLeadInfo": function loadLeadInfo($event) {
        return _vm.updateLeadInfo($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }